<template>
  <div id="upload-image-admission">
    <b-media no-body>
      <b-link @click="$refs.refInputEl.click()">
        <b-avatar
          v-if="!images.file"
          size="200px"
          rounded
        />
        <b-img
          v-if="images.file"
          ref="previewEl"
          :src="images.file"
          width="400px"
          rounded
        />
      </b-link>
      <input
        ref="refInputEl"
        type="file"
        class="d-none"
        @input="inputImageRenderer"
      >
    </b-media>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import {
  BMedia,
  BImg,
  BAvatar,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'UploadImage',
  components: {
    BAvatar,
    BMedia,
    BImg,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      props.images.file = base64
      props.images.fileName = refInputEl.value.files[0].name
      if (props.images.id) delete props.images.id
    })

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
#upload-image-admission {
  .media {
    border: 1px dashed;
    border-radius: 0.357rem;
  }
}
</style>
